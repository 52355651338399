
import './index.scss';

import "intl-tel-input";;
// import Swal from 'sweetalert2';
const Swal = require('sweetalert2')

window.onscroll = function() {myFunction()};

var header = document.getElementById("static-header");
var sticky = header.offsetTop;

function myFunction() {
    if (window.pageYOffset > sticky) {
        $('#logo-getcashi').attr('src','');
        $("#logo-getcashi").attr('src', window.location.protocol + "//" + window.location.host+"/assets/images/cashi-logo2.png");
        header.classList.add("sticky");
    } else {
        $('#logo-getcashi').attr('src','');
        $("#logo-getcashi").attr('src', window.location.protocol + "//" + window.location.host +'/assets/images/cashi-logo.png');
        header.classList.remove("sticky");
    }
}

/// some script

// jquery ready start
$(document).ready(function () {
    // jQuery code

    $("[data-trigger]").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        var offcanvas_id = $(this).attr("data-trigger");
        $(offcanvas_id).toggleClass("show");
        $("body").toggleClass("offcanvas-active");
        $(".screen-overlay").toggleClass("show");
    });

    // Close menu when pressing ESC
    $(document).on("keydown", function (event) {
        if (event.keyCode === 27) {
            $(".mobile-offcanvas").removeClass("show");
            $("body").removeClass("overlay-active");
        }
    });

}); // jquery end

(() => {
    'use strict'

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }
            form.classList.add('was-validated')
        }, false)
    })
})()


const input = document.querySelector("#phone");
const output = document.querySelector("#output");
if(input){
const iti = window.intlTelInput(input, {
    initialCountry: "sd",
    nationalMode: true,
    utilsScript: "../assets/js/utils.js",
    numberType: "MOBILE",
    defaultCountry: "sd",
    geoIpLookup: function (callback) {
        var date = new Date().getTime();
        $.get( "/getipinfo?time=" + date, function () {}).always(
            function (resp) {
                var countryCode = resp && resp.country ? resp.country : "sd";
                callback(countryCode);
            }
        );
    },
    autoHideDialCode: true,
});

var checkForm = false;
const handleChange = () => {
    if (!input.value.trim()) {
        $("#phone").removeClass("valid")
        $("#phone").addClass("notvalid");
        checkForm =false;
    } else if (iti.isValidNumberPrecise()) {
        $("#phone").removeClass("notvalid")
        $("#phone").addClass("valid")
        checkForm =true;
    } else {
        $("#phone").removeClass("valid")
        $("#phone").addClass("notvalid");
        checkForm =false;
    }
};

// listen to "keyup", but also "change" to update when the user selects a country
input.addEventListener('change', handleChange);
input.addEventListener('keyup', handleChange);
}

$("#other_message").hide();
$("#service_request").on('change',function(){
    if($(this).val() == 'other'){
        $("#other_message").slideDown(500)
    }
    else {
        $("#other_message").slideUp(500)
        $("#other_message input").val('')
    }
})

$('form.needs-validation').on('submit', function (e) {
    e.preventDefault();
    if (!this.checkValidity()) {
        showError(message.opps, message.phonenumber);
        return false;
    }
    if (!checkForm) {
        showError(message.opps, message.phonenumber);
        return false;
    }
    var response = grecaptcha.getResponse();
    if (response.length == 0) {
        showRecaptchaError(message.recaptcha);
        return false;
    }
    $('#recaptcha-message').remove();
    var timerInterval;
    Swal.fire({
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
        },
        willClose: () => {
            clearInterval(timerInterval);
        }
    });
    $.ajax({
        type: 'POST',
        // url: 'https://stg-cashisof.alsoug.com/get-cashi/cp-contact-form',
        url: 'https://gw.alsoug.com/get-cashi/cp-contact-form',
        data: $(this).serialize(),
        success: function (response) {
            Swal.close(); 
            if (response.status == 1) {
                Swal.fire({
                    text: response.message,
                    icon: "success",
                    confirmButtonText: message.close,
                });
                setTimeout(() => {
                    window.location.reload(); 
                }, 2000);
            } else {
                showError(message.opps, response.message);
            }
        },
        error: function (error) {
            console.error(error);
            showError(message.opps, error.message);
        }
    });
});
function showError(title, text) {
    Swal.fire({
        icon: "error",
        title: title,
        text: text,
        confirmButtonText: message.close,
    });
}
function showRecaptchaError(text) {
    var messageElement ='';
    messageElement = $('<p>', {
        text: text,
        id: 'recaptcha-message'
    });
    $('.g-recaptcha').append(messageElement);

    messageElement[0].scrollIntoView();
    $('#recaptcha-message').css({ "font-size": "12px", "color": "#fff", "margin": "0" });
}
